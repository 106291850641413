import { defineStore } from "pinia";
import { IHour, IHours } from "../Interfaces/DTO";

export const useHdaStore = defineStore("hdaStore", {
  state: (): IHours => ({
    processData: [],
    isLoading: true,
    currentWeek: "",
    thereAreChanges: false,
    isSimpleLoading: false,
  }),
  getters: {
    getProcessDataByTeamId() {
      return (id: number) => this.processData.find((process) => process.teamId === id);
    },
    getProcessDataByProcessId() {
      return (id: number): IHour => {
        const data = this.processData.find((process) => process.processId === id);
        return !data
          ? {
              date: "",
              hours: 0,
              processId: id,
              teamId: 0,
              userId: 0,
              uuid: "",
            }
          : data;
      };
    },
    getProcessDataByDate() {
      return (date: string) => this.processData.find((process) => process.date === date);
    },
    getProcessDataByUserId() {
      return (id: number) => this.processData.find((process) => process.userId === id);
    },
    getDeclaredHours(state) {
      return (date: string): number => {
        const data = state.processData.filter((hours) => hours.date === date).map(({ hours }) => hours);

        return data.reduce((prevNum, num) => prevNum + num, 0);
      };
    },
    getDeclaredHoursByProcessIdAndDate(state) {
      return (date: string, processId: number): number => {
        const data = state.processData
          .filter((hours) => hours.date === date && hours.processId === processId)
          .map(({ hours }) => hours);

        return data.reduce((prevNum, num) => prevNum + num, 0);
      };
    },
    getWeek(state) {
      return (): string => {
        return state.currentWeek;
      };
    },
  },
  actions: {
    addProcessData(data: IHour): void {
      const optProcess = this.processData.find(
        (process) =>
          process.processId === data.processId && process.date === data.date && process.teamId === data.teamId
      );

      if (!optProcess) {
        this.processData.push(data);
      } else {
        const filterData = this.processData.filter((process) => process.uuid !== optProcess.uuid);
        this.processData = filterData;
        this.processData.push(data);
      }
    },
    resetProcessData(): void {
      this.processData = [];
    },
    loading(value: boolean) {
      this.isLoading = value;
    },
    simpleLoading(value: boolean) {
      this.isSimpleLoading = value;
    },
    setCurrentWeek(value: string) {
      this.currentWeek = value;
    },
    setChanges(value: boolean) {
      this.thereAreChanges = value;
    },
  },
  persist: false,
});
